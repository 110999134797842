import mapValues from 'lodash/mapValues'

const BRANDS = {
  blick: 'blick',
  energy: 'energy',
  beobachter: 'beobachter',
  wirtschaftsmedien: 'wirtschaftsmedien',
  schweizerillustrierte: 'schweizerillustrierte',
  moonandstars: 'moonandstars',
  vintage: 'vintage',
  schlager: 'schlager',
  twentymin: '20min',
  rockit: 'rockit',
  interview: 'interview',
  pme: 'pme',
  illustre: 'illustre',
  lematin: 'lematin',
  onelog: 'onelog',
  baz: 'baz',
  berneroberlander: 'berneroberlander',
  bernerzeitung: 'bernerzeitung',
  bilan: 'bilan',
  bz: 'bz',
  derbund: 'derbund',
  derlandbote: 'derlandbote',
  finanzundwirtschaft: 'finanzundwirtschaft',
  langenthalertagblatt: 'langenthalertagblatt',
  lematindimanche: 'lematindimanche',
  schweizerfamilie: 'schweizerfamilie',
  sihltaler: 'sihltaler',
  sonntagszeitung: 'sonntagszeitung',
  tagesanzeiger: 'tagesanzeiger',
  tamedia: 'tamedia',
  thalwileranzeiger: 'thalwileranzeiger',
  thunertagblatt: 'thunertagblatt',
  tribunedegeneve: 'tribunedegeneve',
  twelveapp: '12app',
  twentyfourhours: '24hours',
  zurcherunterlander: 'zurcherunterlander',
  zurichseezeitung: 'zurichseezeitung',
  lessentiel: 'lessentiel',
  srg: 'srg',
  nzz: 'nzz',
  lz: 'lz',
  cash: 'cash',
  caminada: 'caminada',
  glueckspost: 'glueckspost',
  playsuisse: 'playsuisse',
  gryps: 'gryps',
  jobs: 'jobs',
  jobup: 'jobup',
  jobscout24: 'jobscout24',
  programmzeitschriften: 'programmzeitschriften',
  landliebe: 'landliebe',
  verkehrsmonitor: 'verkehrsmonitor',
  solothurnerzeitung: 'solothurnerzeitung',
  badenertagblatt: 'badenertagblatt',
  thurgauerzeitung: 'thurgauerzeitung',
  aargauerzeitung: 'aargauerzeitung',
  urnerzeitung: 'urnerzeitung',
  oltnertagblatt: 'oltnertagblatt',
  luzernerzeitung: 'luzernerzeitung',
  appenzellerzeitung: 'appenzellerzeitung',
  obwaldnerzeitung: 'obwaldnerzeitung',
  zugerzeitung: 'zugerzeitung',
  bzbasel: 'bzbasel',
  stgallertagblatt: 'stgallertagblatt',
  nidwaldnerzeitung: 'nidwaldnerzeitung',
  limmattalerzeitung: 'limmattalerzeitung',
  grenchnertagblatt: 'grenchnertagblatt',
  nzzmagazin: 'nzzmagazin',
  zff: 'zff',
  ticketcorner: 'ticketcorner',
  jobbern: 'jobbern',
  autodesjahres: 'autodesjahres',
  watson: 'watson',
}

const BRAND_PARTNERS = {
  bilanz: 'bilanz',
  handelszeitung: 'handelszeitung',
  tele: 'tele',
  tvstar: 'tvstar',
  tvvier: 'tvvier',
}

const BREAKPOINTS = {
  mobile: `(max-width: 767px)`,
  tablet: `(min-width: 768px)`,
  desktop: `(min-width: 1025px)`,
}

const LANGUAGES = {
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
  en: 'English',
  pt: 'Português',
  es: 'Español',
  sr: 'Srpski',
  hr: 'Hrvatski',
  bs: 'Bosanski',
  al: 'Shqiptare',
  ua: 'Український',
  ru: 'Русский',
}

const ROUTES = {
  HOME: '/',
  SUPPORT: '/support/',
  AGB: '/agb/',
  PRIVACY_POLICY: '/privacy-policy/',
  IMPRESSUM: '/impressum/',
}

const allLanguages = [...Object.keys(LANGUAGES)]
const COMMON_LANGUAGES = allLanguages.filter(lang => ['de', 'fr', 'it', 'en'].includes(lang))

const LANGUAGES_BY_ROUTE = {
  [ROUTES.HOME]: COMMON_LANGUAGES,
  [ROUTES.SUPPORT]: COMMON_LANGUAGES,
  [ROUTES.AGB]: allLanguages,
  [ROUTES.PRIVACY_POLICY]: allLanguages,
  [ROUTES.IMPRESSUM]: allLanguages,
}

const LANGUAGES_KEYS = mapValues(LANGUAGES, (_, langKey) => langKey)

const DEFAULT_LANGUAGE = LANGUAGES_KEYS.de

const BANNERS = {
  DIGITAL_TRUST: {
    id: 'hide:digital-trust-label-strip',
    whiteListRoutes: [ROUTES.HOME],
  },
}

export {
  BRANDS,
  BRAND_PARTNERS,
  LANGUAGES,
  DEFAULT_LANGUAGE,
  BREAKPOINTS,
  LANGUAGES_KEYS,
  ROUTES,
  LANGUAGES_BY_ROUTE,
  COMMON_LANGUAGES,
  BANNERS,
}
